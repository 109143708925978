<div class="row" xmlns="http://www.w3.org/1999/html">
  <div class="col-md-3">
    <nb-card size="large">
      <button nbButton  (click)="openDialog()">Create Rooms</button>
      <nb-card-header class="centered-header">Friends</nb-card-header>
      <nb-card-body [nbSpinner]="usersLoaded">
        <input nbInput type="search" name="search" id="search" placeholder="Search" class="col-md-12" [(ngModel)]="searchTerm">
          <nb-list>
          <nb-list-item *ngFor="let user of filteredUsers()">
           <nb-user [name]="user.email" [picture]="user.userProfileImage" (click)="selectUser(user.email)" style="cursor: pointer;">
           
<!--            <span (click)="selectUser(user.email)" style="cursor: pointer;"-->
<!--                  [title]="user.email">-->
<!--              {{ user.email.substring(0, user.email.indexOf('@')) }}-->
                <span class="indicator online" *ngIf="user.status === 'Online'"></span>
              <span class="indicator offline" *ngIf="user.status === 'Offline'"></span>
<!--            </span>-->
             </nb-user>
          </nb-list-item>
          <nb-list-item *ngFor="let chatRoom of chatRomms">
           <nb-user [name]="chatRoom.title"  [picture]="userConImg" (click)="selectChatRoom(chatRoom,chatRoom.users)" style="cursor: pointer;">
<!--                <span *ngFor="let participant of chatRoom.users">{{ participant }}</span>-->
            </nb-user>
          </nb-list-item>
          </nb-list>

      </nb-card-body>
    </nb-card>

  </div>
  <div class="col-md-9" [hidden]="chatHidden" *ngIf="otherUser">
    <nb-chat [title]="otherUser" size="large" status="success">
      <nb-chat-message *ngFor="let message of msgsSent"
                       [message]="message.text"
                       [reply]="message.reply"
                       [sender]="message.me.substring(0, message.me.indexOf('@'))"
                       [date]="message.date" dateFormat="dd MMMM YYYY h:mm a"
                       [avatar]="message.avatar"
                       [files]="message.file">
      </nb-chat-message>
      <nb-chat-form class="custom-file-icon" (send)="sendMessage($event)" [showButton]="true" [dropFiles]="true" >
      </nb-chat-form>
          
    <label for="fileInput" class="file-input-label">
      <span class="file-icon clickable" (click)="openFileInput()"><i class="fa fa-upload"></i></span>
      <input type="file" id="fileInput" (change)="sendMessage($event)" accept=".pdf,.doc,.docx,.txt,.jpg,.jpeg,.png" />
    </label>
  </nb-chat>
  </div>
</div>

