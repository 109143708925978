import { Component, OnInit } from '@angular/core';
import {MessageService} from '../../services/message/message.service';
import {Message} from '../../models/message/message';
import {AuthService} from '../../../auth/service/auth.service';
import {Auth} from '../../../auth/model/Auth';
import moment from 'moment';
import {TokenStorageService} from '../../../auth/service/token/token.service';
import {CreateChatRoomComponent} from '../create-chat-room/create-chat-room.component';
import {MatDialog} from '@angular/material/dialog';
import {ChatroomsService} from '../../services/chatroom/chatroom.service';
import {ChatRooms} from '../../models/chatRoom/chat-rooms';
import { faPaperclip } from '@fortawesome/fontawesome-free';
import {EmployeeService} from '../../../humain-capital-management/services/employeeServices/employee.service';

@Component({
  selector: 'ngx-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit {
  faPaperclip = faPaperclip;
  selectedFile: File = null;
  searchTerm: string = '';

  chatRomms: ChatRooms[] = [];
  allMessages: Message[] = [];
  users: Auth[] = [];
  userConnected = JSON.parse(localStorage.getItem('auth-user'));
  public connectedUser: Auth = new Auth();
  otherUser;
  msgsSent: any[] = [];
  messageToSent;
  messageToUpdate;
  chatHidden = true;
  changeColorOnClick = 'text-white';
  messageId;
  moment: any = moment;
  usersLoaded = false;
 userConImg: string;
  constructor(private messageService: MessageService, private authService: AuthService,
              private tokenStorageService: TokenStorageService , private matDialog: MatDialog ,
              private  chatService: ChatroomsService, private emplService: EmployeeService) { }

  selectUser(otherUser) {
    this.allMessages = [];
    this.msgsSent = [];
    this.otherUser = otherUser;
    if (this.chatHidden === true) {
      this.chatHidden = false;
    }
    for (let u = 0; u < this.users.length; u++) {
      if (this.users[u].email === this.otherUser) {
        this.changeColorOnClick = 'text-primary';
      } else {
        this.changeColorOnClick = 'text-white';
      }
    }
    this.messageService.getAllMessages().subscribe(messages => {
      for (let m = 0; m < messages.length; m++) {
        if (messages[m].me === this.connectedUser.email &&
          messages[m].theOther === otherUser || messages[m].me === otherUser &&
          messages[m].theOther === this.connectedUser.email) {
          this.allMessages.push(messages[m]);
          this.msgsSent = messages[m].messages;
          for (let s = 0; s < this.msgsSent.length; s++) {
            this.msgsSent[s].reply = this.connectedUser.email === this.msgsSent[s].me;
          }
          this.messageId = messages[m].messageId;
        }
      }
    });
  }

  sendMessage(event: any) {

    this.msgsSent.push({
      text: event.message,
      date: new Date(),
      me: this.connectedUser.email,
      reply: true,
    });

    this.messageToSent = {
      me: this.connectedUser.email,
      theOther: this.otherUser,
      messages: this.msgsSent,
    };

    this.messageToUpdate = {
      messages: this.msgsSent,
    };

    if (this.allMessages.length === 0) {
      this.messageService.addNewMessage(this.messageToSent).subscribe(() => {
        this.allMessages.push(this.messageToSent);
      });
    } else {
      this.messageService.updateConversation(this.messageToUpdate, this.messageId).subscribe(message => {
        console.log(message);
      });
    }
  }
  message: string = '';

  // sendMessageweb(event: any): void {
  //   if (this.message.trim() !== '') {
  //     this.chatService.sendMessage(this.message);
  //     this.message = '';
  //     console.log(this.message);
  //   }
  // }
  ngOnInit(): void {
    this.usersLoaded = true;
    this.connectedUser = JSON.parse(localStorage.getItem('auth-user'));
    this.connectedUser.companyData = this.tokenStorageService.getUser().company;
    setTimeout(() => {
      this.authService.getAllUsers().subscribe(users => {
        for (let u = 0; u < users.length; u++) {
          if ((users[u].email !== this.connectedUser.email)) {
              this.usersLoaded = false;
              this.users.push(users[u]);
              console.log(this.users);
          }}
      });
    }, 2000);
    // this.loadChatRooms();
    this.chatService.getAllChatRooms().subscribe(
      (chatRoom: ChatRooms[]) => {
        this.chatRomms = chatRoom;
        console.log('test' + this.chatRomms);
      },
    );
    this.userConImg = 'assets/images/logo/Manajero-logo.png';

    this.chatService.openWebSocket();
  }

  filteredUsers() {
    if (!this.searchTerm) {
      return this.users;
    }
    return this.users.filter(user =>
      user.email.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }
  openDialog() {
    this.matDialog.open(CreateChatRoomComponent, {
      width: '500px',
    });
  }
  loadChatRooms() {
    this.chatService.getAllChatRooms().subscribe(
      (chatRooms: ChatRooms[]) => {
        this.chatRomms = chatRooms;
      },
      (error) => {
        console.log('Error retrieving chat rooms:', error);
        console.log(this.chatRomms);

      },
    );
  }

  openFileInput(): void {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  onFileSelected(files: FileList): void {
    this.selectedFile = files.item(0);
  }

  getImageEmployee(id: string): string {
    let img: any;
    this.emplService.getImageEmployee(id).subscribe(response => {
      const fileBlob = response.body;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        img = e.target.result;
      };
      reader.readAsDataURL(fileBlob);
    });
    return img;
  }
  // chatrooms
  messages: string[] = [];
  newMessage: string;
  sendMessageChat() {
    this.chatService.sendMessage(this.newMessage);
    this.newMessage = '';
  }
  selectedChatRoom: any; // Currently selected chat room

  // selectChatRoom(chatRoom: any): void {
  //   this.selectedChatRoom = chatRoom;
  // }

  selectChatRoom(chatRoom: any, otherUser): void {
    // this.selectedChatRoom = chatRoom;
    // this.allMessages = [];
    // this.msgsSent = [];
    // if (this.chatHidden === true) {
    //   this.chatHidden = false;
    // }

    // Fetch group chat messages using WebSocket or other appropriate means
    // Replace the logic below with the actual implementation for fetching group chat messages via WebSocket
    // this.chatService.fetchGroupChatMessages(chatRoom.id).subscribe(messages => {
    //   this.allMessages = messages;
    //   this.msgsSent = messages.filter(message => message.senderId === this.connectedUser.id);
    //   for (let s = 0; s < this.msgsSent.length; s++) {
    //     this.msgsSent[s].reply = true; // Assuming the sender of the message is always the connected user
    //   }
    // });


    this.allMessages = [];
    this.msgsSent = [];
    this.otherUser = otherUser;
    if (this.chatHidden === true) {
      this.chatHidden = false;
    }
    for (let u = 0; u < this.users.length; u++) {
      if (this.users[u].email === this.otherUser) {
        this.changeColorOnClick = 'text-primary';
      } else {
        this.changeColorOnClick = 'text-white';
      }
    }
    this.messageService.getAllMessages().subscribe(messages => {
      for (let m = 0; m < messages.length; m++) {
        if (messages[m].me === this.connectedUser.email &&
          messages[m].theOther === otherUser || messages[m].me === otherUser &&
          messages[m].theOther === this.connectedUser.email) {
          this.allMessages.push(messages[m]);
          this.msgsSent = messages[m].messages;
          for (let s = 0; s < this.msgsSent.length; s++) {
            this.msgsSent[s].reply = this.connectedUser.email === this.msgsSent[s].me;
          }
          this.messageId = messages[m].messageId;
        }
      }
    });

  }

  protected readonly Message = Message;
}
